// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juno-code-js": () => import("./../../../src/pages/juno-code.js" /* webpackChunkName: "component---src-pages-juno-code-js" */),
  "component---src-pages-juno-js": () => import("./../../../src/pages/juno.js" /* webpackChunkName: "component---src-pages-juno-js" */),
  "component---src-pages-make-waves-js": () => import("./../../../src/pages/makeWaves.js" /* webpackChunkName: "component---src-pages-make-waves-js" */),
  "component---src-pages-operator-lookup-js": () => import("./../../../src/pages/operatorLookup.js" /* webpackChunkName: "component---src-pages-operator-lookup-js" */),
  "component---src-pages-picking-apart-kents-useasync-mdx": () => import("./../../../src/pages/picking-apart-kents-useasync.mdx" /* webpackChunkName: "component---src-pages-picking-apart-kents-useasync-mdx" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-use-safe-dispatch-mdx": () => import("./../../../src/pages/useSafeDispatch.mdx" /* webpackChunkName: "component---src-pages-use-safe-dispatch-mdx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

