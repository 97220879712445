import React from 'react'
import { Link } from 'gatsby'
import { motion, useAnimation } from 'framer-motion'

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}
const waves = [
  'M0,160L30,149.3C60,139,120,117,180,128C240,139,300,181,360,176C420,171,480,117,540,106.7C600,96,660,128,720,154.7C780,181,840,203,900,197.3C960,192,1020,160,1080,138.7C1140,117,1200,107,1260,96C1320,85,1380,75,1410,69.3L1440,64L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z',
  'M0,160L30,181.3C60,203,120,245,180,261.3C240,277,300,267,360,234.7C420,203,480,149,540,149.3C600,149,660,203,720,224C780,245,840,235,900,224C960,213,1020,203,1080,165.3C1140,128,1200,64,1260,48C1320,32,1380,64,1410,80L1440,96L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z',
  'M0,256L30,261.3C60,267,120,277,180,277.3C240,277,300,267,360,250.7C420,235,480,213,540,218.7C600,224,660,256,720,245.3C780,235,840,181,900,154.7C960,128,1020,128,1080,149.3C1140,171,1200,213,1260,202.7C1320,192,1380,128,1410,96L1440,64L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z',
  'M0,224L30,234.7C60,245,120,267,180,256C240,245,300,203,360,176C420,149,480,139,540,138.7C600,139,660,149,720,165.3C780,181,840,203,900,176C960,149,1020,75,1080,69.3C1140,64,1200,128,1260,154.7C1320,181,1380,171,1410,165.3L1440,160L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z',
  'M0,224L30,218.7C60,213,120,203,180,213.3C240,224,300,256,360,229.3C420,203,480,117,540,106.7C600,96,660,160,720,186.7C780,213,840,203,900,218.7C960,235,1020,277,1080,250.7C1140,224,1200,128,1260,74.7C1320,21,1380,11,1410,5.3L1440,0L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z',
  'M0,192L30,197.3C60,203,120,213,180,224C240,235,300,245,360,234.7C420,224,480,192,540,202.7C600,213,660,267,720,277.3C780,288,840,256,900,240C960,224,1020,224,1080,208C1140,192,1200,160,1260,154.7C1320,149,1380,171,1410,181.3L1440,192L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z',
]

export default function Waves({ number, opacity, speed }) {
  return (
    <svg viewBox="0 0 1440 800" id="waves">
      <defs>
        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: 'purple', stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{ stopColor: '#a01aed', stopOpacity: 1 }}
          />
        </linearGradient>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="50%">
          <motion.stop
            stopColor="#FF7744"
            animate={{
              stopColor: ['#FF7744', '#4d3e96'],
            }}
            transition={{
              yoyo: Infinity,
              ease: 'linear',
              duration: 8,
            }}
            offset="25%"
          />
          <motion.stop
            stopColor="#BF5FFF"
            animate={{
              stopColor: ['#BF5FFF', '#FFC6A8', '#FF7744', '#5f41f2'],
            }}
            transition={{
              yoyo: Infinity,
              ease: 'linear',
              duration: 8,
            }}
            offset="50%"
          />
          <motion.stop
            stopColor="#5f41f2"
            animate={{
              stopColor: ['#5f41f2', '#BF5FFF'],
            }}
            transition={{
              yoyo: Infinity,
              ease: 'linear',
              duration: 8,
            }}
            offset="75%"
          />
          <motion.stop
            stopColor="#D4504C"
            animate={{
              stopColor: ['#D4504C', '#5f41f2', '#f7d319'],
            }}
            transition={{
              yoyo: Infinity,
              ease: 'linear',
              duration: 8,
            }}
            offset="100%"
          />
        </linearGradient>
      </defs>
      {waves.map((wave, index) => {
        if (index < number) {
          return (
            <motion.path
              d={`${wave}`}
              initial={{ d: `${wave}` }}
              animate={{ d: `${waves[getRandomInt(0, 5)]}` }}
              fillOpacity={opacity}
              fill="url(#grad1)"
              transition={{
                repeat: Infinity,
                repeatType: 'reverse',
                duration: speed + index / 2,
              }}
              key={`${index}-wave`}
            />
          )
        }
      })}
    </svg>
  )
}
